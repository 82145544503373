// //

import numeral from "numeral";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import _ from "lodash";
import { calAmountInterest } from "../pages/debt/component/VIDebtPlan.Main";

export const roundUp = (num, precision) => {
  precision = Math.pow(10, precision);
  return Math.ceil(num * precision) / precision;
};

//
export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  let isInvert = false;
  if (num < 0) {
    num = num * -1;
    isInvert = true;
  }
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (isInvert ? "-" : "") +
        (num / item.value).toFixed(digits).replace(rx, "$1") +
        item.symbol
    : "0";
};

export const getPathURL = (key) => {
  return `https://d1o7c3jhefcaeq.cloudfront.net/public/${key}`;
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result; // Extract the Base64 string (excluding the data URL prefix)
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const dateFormatTH = (inputDateStr) => {
  // Parse the input date string
  const inputDate = new Date(inputDateStr);

  // Month names in Thai
  const thaiMonths = [
    "ม.ค.",
    "ก.พ.",
    "มี.ค.",
    "เม.ย.",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย.",
    "ธ.ค.",
  ];

  // Get day, month, and year components
  const day = inputDate.getDate();
  const month = thaiMonths[inputDate.getMonth()];
  const year = inputDate.getFullYear() + 543; // Convert to Thai Buddhist calendar

  // Format the date string
  const outputDateStr = `${day} ${month} ${year}`;

  return outputDateStr;
};

export const toFloat2 = (num) => parseFloat(numeral(num).format("0.00"));

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function mapStatus(status) {
  switch (status) {
    case "good_2":
      return "อยู่ในเกณฑ์";
    case "good":
      return "ยอดเยี่ยม";
    case "bad":
      return "ปรับปรุง";
    case "chance":
      return "เพิ่มโอกาส";
    default:
      return "ไม่มีข้อมูล";
  }
}

export const mapDebtConditionColor = (condition) => {
  switch (condition) {
    case "normal":
      return "#16AA37";
    case "more_90":
      return "#FF7A00";
    case "less_90":
      return "#FF0000";
    default:
      return "#000000";
  }
};

export function IRR(cashFlows, guess = 0.1) {
  const maxIterations = 1000; // จำนวนครั้งสูงสุดในการทำซ้ำ
  const tolerance = 1e-7; // ความแม่นยำที่ต้องการ

  function npv(rate) {
    return cashFlows.reduce(
      (acc, val, i) => acc + val / Math.pow(1 + rate, i),
      0
    );
  }

  function npvDerivative(rate) {
    return cashFlows.reduce(
      (acc, val, i) => acc - (i * val) / Math.pow(1 + rate, i + 1),
      0
    );
  }

  let rate = guess;
  for (let i = 0; i < maxIterations; i++) {
    const npvValue = npv(rate);
    const npvDerivativeValue = npvDerivative(rate);
    const newRate = rate - npvValue / npvDerivativeValue;

    if (Math.abs(newRate - rate) < tolerance) {
      return newRate;
    }

    rate = newRate;
  }

  return 0;
  // throw new Error("IRR did not converge");
}

export const calPMT = (rate, nperiod, pv, fv, type) => {
  if (!fv) fv = 0;
  if (!type) type = 0;

  if (rate === 0) return -(pv + fv) / nperiod;

  var pvif = Math.pow(1 + rate, nperiod);
  var pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);

  if (type === 1) {
    pmt /= 1 + rate;
  }

  return pmt;
};

export function calculateMonthlyPayment(P, r, n) {
  const monthlyInterestRate = r / 12; // อัตราดอกเบี้ยต่อเดือน
  const numberOfPayments = n * 12; // จำนวนงวดทั้งหมด

  const numerator =
    monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments);
  const denominator = Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1;
  const M = P * (numerator / denominator);

  return M;
}

export function calculateLoanPayments(principal, annualInterestRate, years) {
  // แปลงอัตราดอกเบี้ยต่อปีเป็นอัตราดอกเบี้ยต่อเดือน
  let monthlyInterestRate = annualInterestRate / 12 / 100;

  // แปลงระยะเวลาชำระหนี้เป็นจำนวนเดือน
  let numberOfMonths = years * 12;

  // คำนวณยอดผ่อนต่อเดือน
  let monthlyPayment =
    (principal *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, numberOfMonths)) /
    (Math.pow(1 + monthlyInterestRate, numberOfMonths) - 1);

  // คำนวณดอกเบี้ยจ่ายทั้งหมด
  let totalPayment = monthlyPayment * numberOfMonths;
  let totalInterest = totalPayment - principal;

  return {
    monthlyPayment: toFloat2(monthlyPayment),
    totalInterest: toFloat2(totalInterest),
    totalPayment: toFloat2(totalPayment),
  };
}

export const calTotalCreditorFloatInterest = (item2) => {
  let items = [];
  let remain_after_payment = 0;
  let index = 0;
  const amountInterest = toFloat2((item2.unpaid_amount * item2.interest) / 100);

  do {
    let total_min_payment = 0;
    let interest = 0;
    let payment = item2.pay_permonth;
    let total_amount = 0;
    let total_after_payment = 0;
    if (index === 0) {
      total_min_payment = (item2.unpaid_amount * item2.interest) / 100;
      interest = total_min_payment;
      total_amount = item2.unpaid_amount + interest;
      total_after_payment = total_amount - payment;
    } else {
      const prev_total = items[index - 1].total_after_payment;
      total_min_payment = (prev_total * item2.interest) / 100;
      interest = (prev_total * item2.interest) / 100;
      total_amount = prev_total + interest;
      total_after_payment = total_amount - payment;
    }

    remain_after_payment = total_after_payment;

    items.push({
      index,
      total_min_payment,
      interest,
      payment,
      total_amount,
      total_after_payment,
      remain_after_payment,
    });
    index++;

    if (index === 60 && amountInterest >= item2.pay_permonth) {
      break;
    }
  } while (remain_after_payment > 0);

  return items;
};

const getTotalMin = (item2, amount) => {
  if (["pay_minimum"].includes(item2?.type_payment)) {
    return toFloat2(amount * 0.08);
  } else if (item2?.type_payment === "pay_other") {
    return item2.pay_permonth;
  }
};

const getValueZeroIsNaN = (value) => {
  if (_.isNaN(value)) return 0;
  return value || 0;
};

export const calDebtCreditCard = (item2) => {
  let actual_amount = item2?.unpaid_amount || item2?.start_amount;
  let total_creditor = actual_amount;
  let total_creditor_interest = 0;
  let remain = actual_amount;
  let creditor_items = [];
  let index = 0;
  let row = 0;

  do {
    row++;
    if (index === 0) {
      const total_min = getTotalMin(item2, actual_amount);
      remain -= total_min;
      remain = toFloat2(remain);
      creditor_items.push({
        row,
        index: index,
        total_min: total_min,
        prev_remain: actual_amount,
        remain: remain,
        interest_26_5_5_6: 0,
        interest_6_6_24_6: 0,
        interest_25_6_5_7: 0,
      });
    } else {
      let total_min = 0;
      const prev_remain = creditor_items[index - 1].prev_remain;
      const interest_26_5_5_6 = calAmountInterest(
        prev_remain,
        item2?.interest,
        11
      );
      const interest_6_6_24_6 = calAmountInterest(
        prev_remain,
        item2?.interest,
        19
      );
      const interest_25_6_5_7 = calAmountInterest(remain, item2?.interest, 11);
      remain += interest_26_5_5_6 + interest_6_6_24_6 + interest_25_6_5_7;
      const before_remain = toFloat2(remain);
      total_min = getTotalMin(item2, remain);
      remain -= total_min;
      remain = toFloat2(remain);
      creditor_items.push({
        row,
        index: index,
        total_min: total_min,
        prev_remain: before_remain,
        remain: remain,
        interest_26_5_5_6: interest_26_5_5_6,
        interest_6_6_24_6: interest_6_6_24_6,
        interest_25_6_5_7: interest_25_6_5_7,
      });
    }
    index++;
  } while (remain > 1);

  total_creditor_interest = _.sumBy(creditor_items, (item) => {
    return (
      getValueZeroIsNaN(item.interest_26_5_5_6) +
      getValueZeroIsNaN(item.interest_6_6_24_6) +
      getValueZeroIsNaN(item.interest_25_6_5_7)
    );
  });
  total_creditor += total_creditor_interest;
  return {
    total_creditor: total_creditor,
    total_creditor_interest: total_creditor_interest,
    creditor_items: creditor_items,
  };
};

export function calculateLoanDetails(principal, annualRate, years) {
  // อัตราดอกเบี้ยต่อเดือน
  let monthlyRate = annualRate / 12 / 100;

  // จำนวนเดือนที่ต้องผ่อนชำระ
  let numberOfMonths = years * 12;

  // คำนวณยอดการผ่อนชำระต่อเดือน
  let monthlyPayment =
    (principal * monthlyRate * Math.pow(1 + monthlyRate, numberOfMonths)) /
    (Math.pow(1 + monthlyRate, numberOfMonths) - 1);

  // คำนวณดอกเบี้ยที่ต้องจ่ายทั้งหมด
  let totalInterest = monthlyPayment * numberOfMonths - principal;

  // คำนวณยอดรวมที่เจ้าหนี้จะได้รับ
  let totalPayment = monthlyPayment * numberOfMonths;

  return {
    monthlyPayment: toFloat2(monthlyPayment),
    totalInterest: toFloat2(totalInterest),
    totalPayment: toFloat2(totalPayment),
  };
}

export const round = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

export function calculateLoanTerm(principal, annualRate, monthlyPayment) {
  const r = annualRate / 100 / 12; // Convert annual interest rate to monthly
  const n =
    Math.log(monthlyPayment / (monthlyPayment - principal * r)) /
    Math.log(1 + r);
  const years = n / 12; // Convert months to years
  return round(years, 2);
}

export function calculateLoanTermPeriod(principal, annualRate, monthlyPayment) {
  const r = annualRate / 100 / 12; // Convert annual interest rate to monthly
  const n =
    Math.log(monthlyPayment / (monthlyPayment - principal * r)) /
    Math.log(1 + r);
  return Math.ceil(n); // Number of months (งวด)
}

export function generateAmortizationSchedule(
  principal,
  annualRate,
  monthlyPayment
) {
  const r = annualRate / 100 / 12; // Convert annual interest rate to monthly
  let balance = principal;
  let schedule = [];
  let installment = 1;

  while (balance > 0) {
    let interestPayment = balance * r;
    let principalPayment = monthlyPayment - interestPayment;
    balance -= principalPayment;
    if (balance < 0) {
      principalPayment += balance; // Adjust the last payment
      balance = 0;
    }
    schedule.push({
      installment: installment,
      beginningBalance: round(balance + principalPayment, 2), // Beginning balance before payment
      payment: monthlyPayment,
      interestPayment: round(interestPayment, 2),
      principalPayment: round(principalPayment, 2),
      endingBalance: round(balance, 2),
    });
    installment++;
  }
  return schedule;
}
