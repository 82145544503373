import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceHeaderItemDebtCard,
  PdfBalanceHeaderItemYearCard,
  PdfBalanceItemCard,
  PdfBalanceItemYearCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import { STYLE_PAGE, STYLE_PDF_ITEM_TOTAL } from "../styles/pdf.style";
import {
  options_debt_long,
  options_debt_short,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calDebtLocalOption,
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";

function PdfPageBalananceThree({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);

  const mergeOptions = useMemo(() => {
    return [
      {
        label: "หนี้สินระยะสั้น",
        key: "debt_short_list",
        key_option: "options_debt_short",
        options: options_debt_short,
        icon: "credit-card.svg",
      },
      {
        label: "หนี้สินระยะยาว",
        key: "debt_long_list",
        key_option: "options_debt_long",
        options: options_debt_long,
        icon: "mortgage-loan.svg",
      },
    ];
  }, []);

  const cloneMergeOptions = _.cloneDeep([...mergeOptions]);

  const propertyItems = useMemo(() => {
    return getBalanceItems(balanceData, mergeOptions, calDebtLocalOption);
  }, [balanceData]);


  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="งบดุล" icon="/images/balance.svg" />
      <PdfCard title={"หนี้สิน"}>
        {/* หนี้สิน */}
        <PdfCardWhite className={"flex flex-col"}>
          <PdfHeaderCardText
            title="รวมหนี้สินทั้งหมด"
            titleEnd={`${numeral(balanceData?.debt_propotion).format(
              "0,0"
            )} บาท`}
            icon=""
            classNameRoot="mt-2"
          />

          <PdfBalanceHeaderItemDebtCard title={""} />
          <PdfLine className={"my-2"} />

          <div className="mx-2 font-[500] text-[11px] my-1">หนี้สิน</div>

          {/* ITEMS */}
          {propertyItems.map((propertyItem, index) => {
            return (
              <div key={index} className="flex flex-col mx-2">
                <div className="flex space-x-2 items-center">
                  <div>
                    <img
                      src={`/images/${propertyItem?.icon}`}
                      className="bg-cover"
                    />
                  </div>
                  <div className="text-[11px] font-[500]">
                    {propertyItem?.label}
                  </div>
                </div>

                <PdfCardGray className={"my-1 flex flex-col space-y-2"}>
                  {propertyItem.items.map((item, index) => {
                    return <PdfBalanceItemYearCard key={index} item={item} />;
                  })}
                </PdfCardGray>
              </div>
            );
          })}
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageBalananceThree;
