import { useEffect, useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn, toFloat2 } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
  PdfFamilyHeaderItemCard,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_10,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  key_property_invest_category,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useInvestStore } from "../../../_store/investStore";
import FamilyBarChart from "../component/pdf.c.familyChart";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  DonutChartInvestMain,
  RandomColor,
} from "../../../components/chart/DonutChartMain";

const itemKey = {
  เงินฝาก: "#FFD200",
  ตราสารหนี้: "#B2C465",
  ตราสารทุน: "#EEA73C",
  ตราสารหนี้เอกชน: "#DFCE4C",
  สินทรัพย์ทางเลือก: "#EC723E",
};

function PdfPageInvestTwo({ page }) {
  const balanceData = useBalanceStore((state) => state?.balanceData);
  const setBalanceData = useBalanceStore((state) => state?.setBalanceData);

  const investData = useInvestStore((state) => state.investData);

  const result = investData?.sum_score;

  const usedColors = new Set();

  const getUniqueRandomColor = (usedColors) => {
    let color;
    do {
      color = RandomColor();
    } while (usedColors.has(color));
    usedColors.add(color);
    return color;
  };

  const bgColorItems = balanceData?.property_invest_list?.map((item) => {
    if (!itemKey[item?.label]) {
      return { ...item, color: getUniqueRandomColor(usedColors) };
    }
    return { ...item, color: itemKey[item?.label] };
  });

  useEffect(() => {
    let testObject = { ...balanceData, property_invest_list: bgColorItems };
    // console.log("testObject", testObject);
    setBalanceData(testObject);
  }, []);

  const checkLevel = () => {
    if (result < 15) {
      return "1";
    } else if (result > 15 && result < 21) {
      return "2";
    } else if (result > 22 && result < 29) {
      return "3";
    } else if (result > 30 && result < 36) {
      return "4";
    } else {
      return "5";
    }
  };

  const checkLevelTitle = () => {
    if (result < 15) {
      return "กล้าเสี่ยงต่ำ";
    } else if (result > 15 && result < 21) {
      return "กล้าเสี่ยงปานกลางค่อนข้างต่ำ";
    } else if (result > 22 && result < 29) {
      return "กล้าเสี่ยงปานกลางค่อนข้างสูง";
    } else if (result > 30 && result < 36) {
      return "กล้าเสี่ยงสูง";
    } else {
      return "กล้าเสี่ยงสูงมาก";
    }
  };

  const investItems = useMemo(() => {
    if (!balanceData?.property_invest_list?.length) return [];

    const filterPropertyInvest = balanceData?.property_invest_list?.filter(
      (item) => item?.options_property_invest_category
    );

    const groupInvest = _.groupBy(
      filterPropertyInvest,
      "options_property_invest_category"
    );

    const convertGroupInvest = Object.keys(groupInvest).map((key) => {
      const items = [];
      groupInvest[key].forEach((curr) => {
        const itemKeys = Object.keys(curr);
        for (const itemKey of itemKeys) {
          const itemValue = curr[itemKey];
          if (_.isArray(itemValue)) {
            const total = itemValue.reduce((prev, curr) => {
              return prev + curr.amount;
            }, 0);
            const merge = itemValue.map((thisItem) => {
              return {
                ...thisItem,
                label: thisItem?.title,
                value: (thisItem?.amount / total) * 100,
                color: groupInvest[key]?.[0].color,
              };
            });
            items.push(...merge);
          }
        }
      }, []);
      // console.log("groupInvest", groupInvest[key]?.[0].color);
      return {
        key,
        label: key_property_invest_category[key],
        items,
      };
    });

    return convertGroupInvest;
  }, [balanceData]);

  return (
    <div className="flex flex-col space-y-2">
      {investItems.map((investItem, index) => {
        return (
          <div key={index} className={cn(STYLE_PAGE)}>
            <PdfHeader />
            <PdfLine />
            <PdfHeaderText
              title="การจัดทัพการลงทุน"
              icon="/images/revenue.svg"
            />
            {/* การจัดทัพการลงทุน */}
            <PdfCard
              title={`สัดส่วนการลงทุน${investItem?.label}`}
              className={"flex flex-col space-y-3"}
              classNameTitle="text-left"
            >
              <PdfCardWhite className={cn("flex space-x-2 justify-center")}>
                {/* <DonutChartInvestMain
                  classNameRoot={cn("flex")}
                  className={cn("w-[140px] h-[140px] pt-0")}
                  classNameDetail={cn("space-y-1")}
                  classNameText={cn(STYLE_PDF_TEXT_NORMAL_11)}
                  width={20}
                  height={20}
                  sectionFrom="PdfPageInvestTwo"
                  items={investItem?.items}
                /> */}
              </PdfCardWhite>
            </PdfCard>
            <PdfCard>
              <PdfCardWhite className={cn("flex flex-col")}>
                <div className={cn("flex", STYLE_PDF_TEXT_MEDIUM_11)}>
                  <div className={cn("w-[20%]")}>หัวข้อ</div>
                  <div className={cn("w-[25%]")}>รายการ</div>
                  <div className={cn("w-[20%]")}>มูลค่าปัจจุบัน</div>
                  <div className={cn("w-[15%]")}>ผลตอบแทน (%)</div>
                  <div className={cn("w-[20%] text-right")}>สัดส่วน</div>
                </div>
                <div
                  className={cn("border border-b border-mainblue mt-1")}
                ></div>
                <div className="mt-2 flex flex-col space-y-2">
                  {investItem?.items?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={cn("flex", STYLE_PDF_TEXT_MEDIUM_11)}
                      >
                        <div className={cn("w-[20%]")}>
                          {index === 0 && investItem?.label}
                        </div>
                        <div className={cn("w-[25%]")}>{item?.label}</div>
                        <div className={cn("w-[20%]")}>
                          {numeral(item?.amount).format("0,0")}
                        </div>
                        <div className={cn("w-[15%]")}>
                          {numeral(item?.reward_amount_per_year).format("0.00")}
                        </div>
                        <div className={cn("w-[20%] text-right")}>
                          {numeral(item?.value).format("0.00")}%
                        </div>
                      </div>
                    );
                  })}
                </div>
              </PdfCardWhite>
            </PdfCard>
            <PdfFooter page={page + index} />
          </div>
        );
      })}
    </div>
  );
}

export default PdfPageInvestTwo;
