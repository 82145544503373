import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_GRAY_11,
} from "../styles/pdf.style";
import {
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
  mapIcon,
  mapStatus,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";

function PdfPageFinacialOne({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);

  const sumExpensesInvestSaving = balanceData?.sum_expenses_invest_saving
    ? balanceData?.sum_expenses_invest_saving
    : 0;
  const expenseValue = balanceData?.expenses_propotion
    ? balanceData?.expenses_propotion - sumExpensesInvestSaving
    : 0;

  // รายรับ
  const salaryValue = balanceData?.salary_propotion
    ? balanceData?.salary_propotion
    : 0;
  const expensesPerSalary = ((expenseValue / salaryValue) * 100)?.toFixed(2);

  const countPositiveValues = () => {
    const positiveValues = [
      balanceData?.sum_salary_peryear,
      balanceData?.sum_interest_peryear,
      balanceData?.sum_other_income,
    ]?.filter((value) => value > 0);
    return positiveValues?.length;
  };
  const getResult = () => {
    const numPositiveValues = countPositiveValues();
    if (numPositiveValues === 1) {
      return "normal";
    } else if (numPositiveValues > 1) {
      return "good";
    } else {
      return "false";
    }
  };

  const checkResultExpenses = () => {
    // รายรับ
    const salaryValue = balanceData?.salary_propotion
      ? balanceData?.salary_propotion
      : 0;
    // ค่าใช้จ่ายการออมและลงทุน
    const sumExpensesInvestSaving = balanceData?.sum_expenses_invest_saving
      ? balanceData?.sum_expenses_invest_saving
      : 0;
    // รายจ่ายที่ไม่รวมค่าใช้จ่ายเพื่อการออม
    const expenseValue = balanceData?.expenses_propotion
      ? balanceData?.expenses_propotion - sumExpensesInvestSaving
      : 0;
    if (expenseValue > salaryValue / 2) {
      return "normal";
    } else {
      return "good";
    }
  };
  const getDesc = () => {
    if (getResult() === "normal") {
      return `คุณมีช่องทางรายได้ไม่หลากหลาย`;
    }
    return `คุณมีรายได้หลากหลายช่องทาง`;
  };

  // รายรับ
  const salaryValueIncome = balanceData?.salary_propotion
    ? balanceData?.salary_propotion
    : 0;
  const expensesPerSalaryOutcome = (
    (expenseValue / salaryValueIncome) *
    100
  )?.toFixed(2);
  const getDescOutcome = () => {
    if (checkResultExpenses() === "normal") {
      return `คุณมีรายจ่าย รวม ${numeral(expenseValue)?.format(
        "0,0"
      )} บาท หรือ ${
        String(expensesPerSalaryOutcome) === "Infinity"
          ? 0
          : expensesPerSalaryOutcome
      } % ซึ่งมากกว่าครึ่งนึงของรายรับ`;
    }

    return `คุณมีรายจ่าย รวม ${numeral(expenseValue)?.format(
      "0,0"
    )} บาท หรือ ${expensesPerSalaryOutcome} % ซึ่งน้อยกว่าครึ่งนึงของรายรับ`;
  };

  // สภาพคล่องพื้นฐาน
  const checkProperty = useMemo(() => {
    let sum = 0;
    const expenses_permonth = balanceData?.expenses_propotion / 12;
    if (balanceData?.sum_property_asset) {
      sum =
        expenses_permonth > 0
          ? balanceData?.sum_property_asset / expenses_permonth
          : 0;
    }
    return Math.round(sum);
  }, [balanceData]);
  const checkResultProperty = () => {
    if (checkProperty < 6) {
      return "bad";
    } else if (checkProperty === 6) {
      return "good";
    } else {
      return "chance";
    }
  };
  const checkPropertyIcon = () => {
    const status = checkResultProperty();
    if (status === "bad") {
      return "/images/bad_finance.svg";
    }
    if (status === "good") {
      return "/images/happy_finance.svg";
    }
    if (status === "chance") {
      return "/images/chance_finance.svg";
    }
  };
  const checkPropertTitle = () => {
    const status = checkResultProperty();
    if (status === "bad") {
      return (
        <div className="text-[14px] font-semibold text-center  text-[#FF0000]">
          คุณมีอัตราส่วนสภาพคล่อง {numeral(checkProperty)?.format("0,0")} เท่า
          ซึ่งน้อยกว่า 6 เท่า
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="text-[14px] font-semibold text-center   text-maingreen">
          คุณมีอัตราส่วนสภาพคล่องพื้นฐาน 6 เท่า
        </div>
      );
    }
    return (
      <div className="text-[14px] font-semibold text-center   text-[#654321] ">
        คุณมีอัตราส่วนสภาพคล่อง {numeral(checkProperty)?.format("0,0")} เท่า
        ซึ่งมากกว่า 6 เท่า
      </div>
    );
  };

  const checkAmouthProperty = () => {
    const status = checkResultProperty();
    if (status === "bad") {
      return `${checkProperty} เท่า`;
    }
    if (status === "good") {
      return `${checkProperty} เท่า`;
    }
    return `${checkProperty} เท่า`;
  };
  const checkPropertyDes = () => {
    const status = checkResultProperty();
    const value = checkAmouthProperty();
    const expenses_permonth = balanceData?.expenses_propotion / 12;
    const asset_value = +expenses_permonth * (6 - checkProperty);
    const expenses_permonth_six = Math.floor(expenses_permonth) * 6;
    let remain = balanceData?.sum_property_asset - expenses_permonth_six;
    if (remain < 0) {
      remain = 0;
    }

    if (status === "bad") {
      return (
        <div className=" ">
          คุณต้องหาเงินสำรองเพิ่มอีก
          <span className=" px-1 font-bold text-red-500 ">
            {numeral(asset_value).format("0,0")}
          </span>
          บาท*
          <div>เพื่อความไม่ประมาทในการวางแผนการเงิน</div>
          <div className=" text-sm text-maingray mt-4 font-normal ">
            * ค่าใช้จ่ายแต่ละเดือน x ( 6 - สภาพคล่อง )
            <div className=" text-xs mt-1">
              (
              <span className=" text-mainblue font-semibold pl-1">
                {numeral(balanceData?.sum_property_asset).format("0,0")}
              </span>{" "}
              x ( 6 - {numeral(value)?.format("0,0")}) ={" "}
              {numeral(asset_value).format("0,0")} )
            </div>
          </div>
        </div>
      );
    }

    if (status === "good") {
      return (
        <div className="">
          คุณมีความสามารถในการรับมือกับ
          <div>
            เหตุการณ์ไม่คาดฝันได้ดี เช่น ตกงาน อุบัติเหตุทำให้สูญเสียรายได้
            เป็นต้น
          </div>
        </div>
      );
    }
    return (
      <div className="">
        คุณมีเงินสำรองจำนวนมาก
        <div>
          จะดีกว่าไหมหากนำเงิน
          <span className=" px-1 font-bold text-[#654321] ">
            {numeral(remain).format("0,0")}
          </span>
          บาท*
          <div> ไปลงทุนเพิ่มเติม เพื่อให้บรรลุเป้าหมายทางการเงิน</div>
        </div>
      </div>
    );
  };

  // หนี้สินต่อทรัพย์สิน
  const getDebttoTotalAsset = useMemo(() => {
    let sum = 0;
    if (balanceData?.debt_propotion) {
      sum =
        balanceData?.property_propotion > 0
          ? (balanceData?.debt_propotion / balanceData?.property_propotion) *
            100
          : 0;
    }
    return sum;
  }, [balanceData]);
  const checkDebttoAssetIcon = () => {
    const status = getDebttoTotalAsset;
    if (status > 50) {
      return "/images/bad_finance.svg";
    } else {
      return "/images/happy_finance.svg";
    }
  };
  const checkDebttoAssetTitle = () => {
    const status = getDebttoTotalAsset;
    if (status > 50) {
      return (
        <div className="text-[13px] font-semibold text-center  text-[#FF0000]">
          คุณมีอัตราส่วนหนี้สินต่อทรัพย์สิน {getDebttoTotalAsset?.toFixed(2)} %
          ซึ่งมากกว่า 50%
        </div>
      );
    } else {
      return (
        <div className="text-[13px] font-semibold text-center  text-maingreen">
          คุณมีอัตราส่วนหนี้สินต่อทรัพย์สิน {getDebttoTotalAsset?.toFixed(2)}%
          ซึ่งน้อยกว่า 50%
        </div>
      );
    }
  };

  const evulateIncomeExpensesItems = useMemo(() => {
    return [
      {
        label: "รายรับ",
        status: salaryValue === 0 ? "no_data" : getResult(),
        desc: salaryValue === 0 ? "" : getDesc(),
        icon: "income.svg",
        detail:
          salaryValue === 0
            ? ""
            : "คุณน่าจะเพิ่มช่องทางการหารายได้เช่น การลงทุนให้ได้เงินปันผล, การออมเงินเพื่อดอกเบี้ย หรือเพิ่มอาชีพที่ 2 เพื่อช่วยให้คุณบรรลุเป้าหมายทางการเงิน",
      },
      {
        label: "รายจ่าย",
        status: checkResultExpenses(),
        icon: "outcomepdf.svg",
        desc: getDescOutcome(),
        detail:
          "คุณน่าจะลดค่าใช้จ่าย แล้วนำเงินมาเพิ่มการออมและลงทุนเพื่อเป้าหมายทางการเงิน",
      },
    ];
  }, [balanceData]);

  const evulatePropertyItems = useMemo(() => {
    const newPropertyItems = [
      {
        label: "สภาพคล่องพื้นฐาน",
        label_gray: "(ควรมี 6 เท่า)",
        label_icon: "passbook.svg",
        icon: checkPropertyIcon(),
        title: checkPropertTitle(),
        desc: `อัตราส่วนสภาพคล่องพื้นฐาน = สินทรัพย์สภาพคล่อง/ค่าใช้จ่ายต่อเดือน`,
        desc_gray: ` ( ${numeral(checkProperty)?.format("0,0")} เท่า = 
        ${numeral(balanceData?.sum_property_asset).format("0,0")} /
        ${numeral(balanceData?.expenses_propotion / 12).format("0,0")} )`,
        // detail: `สามารถรับมือกับเหตุการณ์ไม่คาดฝันได้ดี เช่น
        // ตกงานสูญเสียรายได้โดยสัดส่วนเงินสำรองนี้ควร มี 3-6 เท่า
        // ของรายจ่ายต่อเดือน และอาจถึง 12 เท่า
        // ถ้ามีความเสี่ยงที่จะไม่สามารถหารายได้ในเวลาอันใกล้`,
        detail: checkPropertyDes(),
      },
      {
        label: "หนี้สินต่อทรัพย์สิน",
        label_gray: "(ไม่ควรเกิน 50%)",
        label_icon: "cost (1).svg",
        icon: checkDebttoAssetIcon(),
        title: checkDebttoAssetTitle(),
        desc: `อัตราส่วนหนี้สินต่อทรัพย์สิน = หนี้สินทั้งหมด / สินทรัพย์ทั้งหมด * 100`,
        desc_gray: `${numeral(getDebttoTotalAsset)?.format(
          "0,0.00"
        )} % = (( ${numeral(balanceData?.debt_propotion)?.format("0,0")} /
        ${numeral(balanceData?.property_propotion)?.format("0,0")}) * 100 )`,
        detail: `ภาระหนี้สินไม่มากเกินหนี้สินที่ดีก่อให้เกิดรายได้ และสินทรัพย์ในอนาคต`,
      },
    ];
    return newPropertyItems;
  }, []);

  // hex น้ำตาลเข้ม #654321

  // console.log("propertyItem:",evulatePropertyItems.map((propertyItem) => propertyItem.desc_gray));

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="รายรับ-รายจ่าย"
        icon="/images/financial_analysis.svg"
      />
      <PdfCard title={""}>
        <div className="grid grid-cols-2 gap-3">
          {evulateIncomeExpensesItems.map((item, index) => {
            return (
              <PdfCardWhite key={index} className={"flex flex-col"}>
                <div className="flex flex-col mx-2">
                  <div className="flex space-x-2 items-center">
                    <div>
                      <img src={`/images/${item.icon}`} className="bg-cover" />
                    </div>
                    <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                      {item.label}
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    {item?.status !== "no_data" && (
                      <div>
                        <img src={`/images/${mapIcon(item.status)}`} />
                      </div>
                    )}
                    <div
                      className={cn(
                        "mt-2 bg-[#28CB4C4D] w-[100px] h-[26px] flex items-center justify-center rounded-[6.67px] text-[13px] font-[500] text-[#16AA37]",
                        {
                          "text-maingreen ": item.status === "good",
                          "text-[#654321] bg-[#FFA800]/30":
                            item.status === "normal",
                          "text-maingray": item.status === "no_data",
                        }
                      )}
                    >
                      {mapStatus(item.status)}
                    </div>
                    <div
                      className={cn(
                        "mt-2 flex items-center justify-center text-[13px] font-[500] text-[#16AA37] text-center",
                        {
                          "text-maingreen": item.status === "good",
                          "text-[#654321]": item.status === "normal",
                          "text-maingray": item.status === "no_data",
                        }
                      )}
                    >
                      {item.desc}
                    </div>
                    <div
                      className={cn(
                        "mt-1 flex items-center justify-center text-center",
                        STYLE_PDF_TEXT_NORMAL_11
                      )}
                    >
                      {item.detail}
                    </div>
                  </div>
                </div>
              </PdfCardWhite>
            );
          })}
        </div>
      </PdfCard>
      <PdfHeaderText
        title="อัตราส่วนทางการเงิน"
        icon="/images/financial_analysis.svg"
      />
      <PdfCard title={""}>
        {evulatePropertyItems.map((propertyItem, index) => {
          return (
            <PdfCardWhite key={index} className={"flex flex-col "}>
              <div className="flex flex-col mx-2">
                <div className="flex space-x-2 items-center">
                  <div>
                    <img
                      src={`/images/${propertyItem.label_icon}`}
                      className="bg-cover"
                    />
                  </div>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    {propertyItem.label}
                  </div>
                  <div
                    className={cn("text-[#7E8393]", STYLE_PDF_TEXT_MEDIUM_11)}
                  >
                    {propertyItem.label_gray}
                  </div>
                </div>

                <div className="flex flex-col items-center mt-1">
                  <div className="flex space-x-2 items-center justify-center">
                    <div>
                      <img
                        src={propertyItem.icon}
                        className="bg-cover w-[24px] h-[24px]"
                      />
                    </div>
                    <div className={cn(STYLE_PDF_TEXT_SEMIBOLD_BLUE_14)}>
                      {propertyItem.title}
                    </div>
                  </div>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_11, "mt-1")}>
                    {propertyItem.desc}
                  </div>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_GRAY_11, "mt-1")}>
                    {propertyItem.desc_gray}
                  </div>
                  <PdfCardGray className={cn("py-2")}>
                    <div className="flex justify-center items-center px-10">
                      <img src="/images/Lamp.svg" className={cn("")} />
                      <div className={cn(" ml-1", STYLE_PDF_TEXT_NORMAL_11)}>
                        {propertyItem.detail}
                      </div>
                    </div>
                  </PdfCardGray>
                </div>
              </div>
            </PdfCardWhite>
          );
        })}
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageFinacialOne;
