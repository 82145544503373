export const balanceNamelist = {
  salary_permonth: "เดือนละ",
  salary_peryear: "ปีละ",
  interest_peryear: "ปีละ",
};

export const formatBalance = (formBL) => {
  let result = [];
  // console.log(formBL);
  for (const key in balanceNamelist) {
    if (formBL?.[key] !== 0 && formBL?.[key] !== "") {
      result.push({
        title: balanceNamelist[key],
        amount: formBL?.[key],
      });
    }
  }

  return result;
};

export const sumAllDebt = (value1, value2, value3) => {
  let result = 0;
  result = value1 + value2 + value3;
  return result;
};

export const calPeryear = (value) => {
  let result = 0;
  result = value * 12;
  return result;
};

//options
export const options_other_income = [
  {
    label: "โบนัส",
    value: "other_bous",
  },
  {
    value: "other_dividend",
    label: "เงินปันผล",
  },
  {
    value: "other_commission",
    label: "ค่านายหน้า",
  },
  {
    value: "other_rent_nonviolence",
    label: "ค่าเช่าอสังหาฯ",
  },
  {
    value: "other_income_selling",
    label: "รายได้จากการขายสินทรัพย์",
  },
  {
    value: "other_income_other",
    label: "อื่น ๆ",
  },
];

export const options_other_income_interest = [
  {
    value: "interest_saving_account",
    label: "บัญชีออมทรัพย์",
  },
  {
    value: "interest_fixed_deposit_account",
    label: "บัญชีฝากประจำ",
  },
  {
    value: "interest_cooperative_account",
    label: "สหกรณ์",
  },

  {
    value: "interest_other",
    label: "อื่น ๆ",
  },
];

//---- Options รายจ่ายคงที่ ----//
export const options_expenses_fixed = [
  {
    label: "ค่าเบี้ยประกันชีวิตรายปี",
    value: "expenses_insuarant_life",
  },
  {
    label: "ค่าเบี้ยประกันอุบัติเหตุรายปี",
    value: "expenses_insuarant_accident",
  },
  {
    label: "ค่าเบี้ยประกันรถยนต์รายปี",
    value: "expenses_insuarant_car",
  },
  {
    label: "ค่าเบี้ยประกันอัคคีภัยรายปี",
    value: "expenses_insuarant_fire",
  },
  {
    label: "ค่าเบี้ยประกันบ้านรายปี",
    value: "expenses_insuarant_home",
  },
  {
    label: "ค่าส่วนกลางหมู่บ้านต่อเดือน (บาท/เดือน)",
    value: "expenses_insuarant_village",
  },
  {
    label: "ประกันสังคมต่อเดือน (บาท /เดือน)  ",
    value: "expenses_insuarant_social",
  },
  {
    label: "เงินสะสมกองทุนสำรองเลี้ยงชีพต่อปี",
    value: "expenses_provide_fund",
  },
  {
    label: "ค่าใช้จ่ายคงที่อื่น ๆ",
    value: "expenses_fixed_other",
  },
];
// สถานะกรมธรรม์
export const INSURANCE_POLICY_STATUS = [
  {
    label: "ปกติ",
    value: "normal",
  },
  {
    label: "ขยายเวลา",
    value: "extend_time",
  },
  {
    label: "มูลค่าสำเร็จ",
    value: "success_value",
  },
  {
    label: "ขาดอายุ",
    value: "expired",
  },
  {
    label: "บอกล้างสัญญา",
    value: "clear_contract",
  },
];
// ประเภทกรมธรรม์
export const INSURANCE_POLICY_TYPE = [
  {
    label: "ความเสี่ยงภัยทั้งหมด",
    value: "all_risks",
  },
  {
    label: "ทรัพย์สินและอัคคีภัย",
    value: "property_fire",
  },
];
// ประเภทกรมธรรม์ รถยนต์
export const INSURANCE_POLICY_TYPE_CAR = [
  {
    label: "สมัครใจ ประเภท 1",
    value: "voluntary_one",
  },
  {
    label: "สมัครใจ ประเภท 2",
    value: "voluntary_two",
  },
  {
    label: "สมัครใจ ประเภท 2+",
    value: "voluntary_two_plus",
  },
  {
    label: "สมัครใจ ประเภท 3",
    value: "voluntary_three",
  },
  {
    label: "สมัครใจ ประเภท 3+",
    value: "voluntary_three_plus",
  },
  {
    label: "ภาคบังคับ",
    value: "compulsory",
  },
];

// ค่าใช้จ่ายผันแปร
export const options_expenses_variable = [
  {
    label: "ค่าอาหาร",
    value: "expenses_food",
  },
  {
    label: "ค่าอุปโภค",
    value: "expenses_consumer",
  },
  {
    label: "ค่าสาธารณูปโภค",
    value: "expenses_public_utilities",
  },
  {
    label: "ค่าโทรศัพท์",
    value: "expenses_mobile",
  },
  {
    label: "ค่านันทนาการ",
    value: "expenses_recreation",
  },
  {
    label: "ภาษีบุคคลธรรมดา รายปี",
    value: "expenses_tax",
  },
  {
    label: "ภาษีดอกเบี้ยเงินฝาก",
    value: "expenses_deposit_interest",
  },
  {
    label: "ภาษีอัตราสารหนี้",
    value: "expenses_debt_instruments",
  },
  {
    label: "ภาษีเงินปันผล",
    value: "expenses_dividend",
  },
  {
    label: "ภาษีรถยนต์",
    value: "expenses_tax_car",
  },
  {
    label: "ภาษีอื่น ๆ",
    value: "expenses_tax_other",
  },
  {
    label: "ค่าน้ำมัน",
    value: "expenses_gasoline",
  },
  {
    label: "ค่าดูแลรักษารถยนต์",
    value: "expenses_car_maintenance",
  },
  {
    label: "ค่าโดยสารรถสาธารณะ",
    value: "expenses_transport",
  },
  {
    label: "ค่าแม่บ้าน",
    value: "expenses_maid",
  },
  {
    label: "ค่ารักษาพยาบาล",
    value: "expenses_medical",
  },
  {
    label: "บริจาค",
    value: "expenses_donate",
  },
  {
    label: "ค่าใช้จ่ายอื่น ๆ",
    value: "expenses_variable_other",
  },
];

// ค่าใช้จ่ายเพื่อการออม / ลงทุน
export const options_expenses_invest = [
  {
    label: "เงินลงทุน",
    value: "expenses_invest",
  },
  {
    label: "เงินออมวัยเกษียณ",
    value: "expenses_conservation",
  },
  {
    label: "เงินออมกองทุน RMF",
    value: "expenses_rmf",
  },
  {
    label: "อื่น ๆ",
    value: "expenses_other",
  },
];

export const options_debt_short = [
  {
    label: "หนี้บัตรเครดิต",
    value: "debt_creditcard",
  },
  {
    label: "หนี้เงินกู้ระยะสั้น",
    value: "debt_shortterm",
  },
  {
    label: "หนี้นอกระบบ (รายวัน)",
    value: "debt_informal_shortterm",
  },
  {
    label: "หนี้เงินกู้ระยะสั้นอื่น ๆ (ดอกลอย)",
    value: "debt_shortterm_other",
  },
];

export const options_debt_long = [
  {
    label: "สินเชื่อรถยนต์ / จักรยานยนต์",
    value: "debt_car",
  },
  {
    label: "สินเชื่อที่อยู่อาศัย",
    value: "debt_place",
  },
  {
    label: "หนี้สินระยะยาวอื่น ๆ",
    value: "debt_long_other",
  },
];

export const OPTIONS_TYPE_CAR = [
  {
    label: "รถยนต์",
    value: "type_car",
  },
  {
    label: "กระบะ",
    value: "type_pickup",
  },
  {
    label: "รถบรรทุก",
    value: "type_truck",
  },
  {
    label: "รถจักรยานยนต์",
    value: "type_motorcycle",
  },
  {
    label: "รถจักรยาน",
    value: "type_bicycle",
  },
];

export const OPTIONS_TYPE_PLACE = [
  {
    label: "บ้านเดี่ยว",
    value: "type_home",
  },
  {
    label: "ทาวน์เฮ้าส์",
    value: "type_townhouse",
  },
  {
    label: "คอนโด",
    value: "type_condo",
  },
  {
    label: "ตึกแถว",
    value: "type_building",
  },
  {
    label: "อื่น ๆ",
    value: "type_place_other",
  },
];

// สินทรัพย์สภาพคล่อง
export const options_property_asset = [
  {
    label: "เงินสด",
    value: "property_cash",
  },
  {
    label: "บัญชีเงินฝากออมทรัพย์",
    value: "property_saving",
  },
  {
    label: "บัญชีเงินฝากประจำ",
    value: "property_deposit",
  },
  {
    label: "เงินฝากสหกรณ์ออมทรัพย์",
    value: "property_saving_cooperative",
  },
  {
    label: "กองทุนตราสารหนี้สินระยะสั้น",
    value: "property_fund_debt",
  },
  // {
  //   label: "มูลค่าทองคำรูปพรรณ",
  //   value: "property_gold",
  // },
];

export const key_property_invest_category = {
  property_debt_instruments: "ตราสารทุน",
  property_equity_instruments: "เงินฝาก",
  property_money_market: "สินทรัพย์ทางเลือก",
  property_other: "สินทรัพย์ทางเลือก",
  property_invest_goverment: "ตราสารหนี้",
  property_invest_private: "ตราสารหนี้เอกชน",
};

// Option สินทรัพส์เพื่อการลงทุน
// property_debt_instruments ตราสารทุน
// property_equity_instruments ตราสารหนี้สิน
// property_money_market ตลาดเงิน
// property_other อื่น ๆ
export const options_property_invest = [
  {
    label: "พันธบัตร/หุ้นกู้ (ตราสารหนี้)",
    value: "property_invest_bond",
    category: "property_invest_goverment",
  },
  {
    label: "หุ้นบุริมสิทธิ์ (ตราสารทุน)",
    value: "property_invest_preferred",
    category: "property_debt_instruments",
  },
  {
    label: "หุ้นสามัญ (ตราสารทุน)",
    value: "property_invest_normal",
    category: "property_debt_instruments",
  },
  {
    label: "กองทุนรวมตลาดเงิน (ตลาดเงิน)",
    value: "property_invest_market",
    category: "property_money_market",
  },
  {
    label: "กองทุนรวมหุ้น (ตราสารทุน)",
    value: "property_all_invest",
    category: "property_debt_instruments",
  },
  {
    label: "กองทุนรวมเพื่อการเลี้ยงชีพ (ตราสารทุน)",
    value: "property_invest_life",
    category: "property_debt_instruments",
  },
  {
    label: "กองทุนรวมผสม (ตราสารทุน)",
    value: "property_invest_mixed",
    category: "property_debt_instruments",
  },
  {
    label: "กองทุนสำรองเลี้ยงชีพ (ตราสารหนี้)",
    value: "property_invest_provident_fund",
    category: "property_equity_instruments",
  },
  {
    label: "เงินออมเพื่อวัยเกษียณอื่น ๆ (ตลาดเงิน)",
    value: "property_invest_other_retirement_savings",
    category: "property_money_market",
  },
  {
    label: "ตราสารอนุพันธ์ (สินทรัพย์ทางเลือก)",
    value: "property_invest_derivative_instruments",
    category: "property_other",
  },
  {
    label: "มูลค่าเงินสดในกรมธรรม์ประกันชีวิต (ตราสารหนี้)",
    value: "property_invest_life_insurance_policy",
    category: "property_equity_instruments",
  },
  {
    label: "อสังหาริมทรัพย์ (สินทรัพย์ทางเลือก)",
    value: "property_invest_real_estate",
    category: "property_other",
  },
  {
    label: "มูลค่าทองคำ (สินทรัพย์ทางเลือก)",
    value: "property_invest_gold_cost",
    category: "property_other",
  },
  {
    label: "หุ้นในส่วนที่เป็นเจ้าของ (ตราสารทุน)",
    value: "property_invest_owner",
    category: "property_debt_instruments",
  },
  {
    label: "เงินลงทุนอื่น ๆ (สินทรัพย์ทางเลือก)",
    value: "property_invest_other",
    category: "property_other",
  },
  {
    label: "เงินฝาก",
    value: "property_invest_deposit",
    category: "property_equity_instruments",
  },
  // {
  //   label: "ตราสารหนี้ภาครัฐ",
  //   value: "property_invest_goverment",
  //   category: "property_invest_goverment",
  // },
  // {
  //   label: "ตราสารหนี้เอกชน",
  //   value: "property_invest_private",
  //   category: "property_invest_private",
  // },
];

// สินทรัพย์ใช้ส่วนตัว
export const options_property_private = [
  {
    label: "เครื่องประดับ",
    value: "property_accessories",
  },
  {
    label: "รถยนต์",
    value: "property_car",
  },
  {
    label: "บ้านที่อยู่อาศัย",
    value: "property_home",
  },
  {
    label: "ของสะสมมีราคา",
    value: "property_collection",
  },
  {
    label: "อื่น ๆ",
    value: "property_other",
  },
];

// สินทรัพย์ไม่มีตัวตน
export const options_property_intangible = [
  {
    label: "ลิขสิทธิ์",
    value: "property_copyright",
  },
  {
    label: "สิทธิบัตร",
    value: "property_patent",
  },
  {
    label: "อื่น ๆ",
    value: "property_intangible_other",
  },
];

export const OPTIONS_TARGET_INVEST = [
  {
    label: "เพื่อการศึกษาบุตร",
    value: "property_invest_education",
  },

  {
    label: "เพื่อการเกษียณ",
    value: "property_invest_retirement",
  },
  {
    label: "อื่น ๆ",
    value: "property_invest_other",
  },
];

export const OPTIONS_COMPOUNDING = [
  {
    label: "รายวัน",
    value: "daily",
  },
  {
    label: "รายสัปดาห์",
    value: "weekly",
  },
  {
    label: "รายเดือน",
    value: "monthly",
  },
  {
    label: "รายปี",
    value: "yearly",
  },
];
