import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
} from "../styles/pdf.style";
import {
  options_expenses_fixed,
  options_expenses_invest,
  options_expenses_variable,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calExpenseFixedOption,
  calExpenseVariableMonthOption,
  calExpenseVariableOption,
  calPropertyLocalOption,
  getAllBalanceTotal,
  getAllPropertyTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";

function PdfPageBalananceSix({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);

  const mergeOptions = useMemo(() => {
    return [
      {
        label: "ค่าใช้จ่ายคงที่",
        key: "expenses_fixed_list",
        key_option: "options_expenses_fixed",
        options: options_expenses_fixed,
        icon: "accounting.svg",
        calLocalOption: calExpenseFixedOption,
        calLocalMonthOption: () => {
          return 0;
        },
      },
      {
        label: "ค่าใช้จ่ายเพื่อออม/ลงทุน",
        key: "expenses_invest_saving_list",
        key_option: "options_expenses_invest",
        options: options_expenses_invest,
        icon: "piggy-bank.svg",
        calLocalOption: calExpenseVariableOption,
        calLocalMonthOption: calExpenseVariableMonthOption,
      },
      {
        label: "ค่าใช้จ่ายแปรผัน",
        key: "expenses_variable_list",
        key_option: "options_expenses_variable",
        options: options_expenses_variable,
        icon: "cost (1).svg",
        calLocalOption: calExpenseVariableOption,
        calLocalMonthOption: () => {
          return 0;
        },
      },
    ];
  }, [balanceData]);

  const propertyItems = useMemo(() => {
    return getBalanceItems(balanceData, mergeOptions);
  }, [balanceData]);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="รายรับ-รายจ่าย" icon="/images/balance.svg" />
      <PdfCard title={"รายจ่าย"}>
        {/* ค่าใช้จ่ายแปรผัน */}
        <PdfCardWhite className={"flex flex-col"}>
          <PdfHeaderCardText
            title="รวมรายจ่ายทั้งหมด"
            titleEnd={`${numeral(balanceData?.expenses_propotion).format(
              "0,0"
            )} บาท`}
            icon=""
            classNameRoot="mt-2"
          />
          <PdfBalanceHeaderItemCard title={"แหล่งที่มาของรายจ่าย"} />
          <PdfLine className={"my-2"} />

          <div className="mx-2 font-[500] text-[11px] my-1">รายจ่าย</div>

          {propertyItems
            .filter((propertyItem) => propertyItem.label !== "ค่าใช้จ่ายแปรผัน")
            .map((propertyItem, index) => {
              return (
                <div key={index} className="flex flex-col mx-2">
                  <div className="flex space-x-2 items-center">
                    <div>
                      <img
                        src={`/images/${propertyItem?.icon}`}
                        className="bg-cover"
                      />
                    </div>
                    <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                      {propertyItem?.label}
                    </div>
                  </div>

                  <PdfCardGray className={"my-1 flex flex-col space-y-2"}>
                    {propertyItem.items.map((item, index) => {
                      if (
                        propertyItem?.label === "ค่าใช้จ่ายคงที่" &&
                        item?.label === "รวม"
                      ) {
                        return (
                          <PdfBalanceItemCard
                            key={index}
                            item={{
                              ...item,
                              amount_per_year: balanceData?.sum_expenses_fixed,
                            }}
                          />
                        );
                      }

                      if (
                        propertyItem?.label === "ค่าใช้จ่ายเพื่อออม/ลงทุน" &&
                        index === 0
                      ) {
                        return (
                          <PdfBalanceItemCard
                            key={index}
                            item={{
                              ...item,
                              label:
                                "ค่าใช้จ่ายเพื่อออม/ลงทุนเพิ่มเติม (จากรายได้หักรายจ่าย)",
                              amount_per_year: balanceData?.temp_net_cash_flow,
                            }}
                          />
                        );
                      }
                      return (
                        <PdfBalanceItemCard
                          key={index}
                          item={{
                            ...item,
                            amount_per_year:
                            item?.label === "รวม"
                                ? item?.amount_per_year +
                                  balanceData?.temp_net_cash_flow
                                : item?.amount_per_year,
                          }}
                        />
                      );
                    })}
                  </PdfCardGray>
                </div>
              );
            })}
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageBalananceSix;
