import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import {
  calDebtCreditCard,
  calTotalCreditorFloatInterest,
  cn,
  generateAmortizationSchedule,
  mapDebtConditionColor,
  round,
} from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_MEDIUM_BLUE_13,
  STYLE_PDF_TEXT_NORMAL_12,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_GRAY_11,
} from "../styles/pdf.style";
import {
  OPTIONS_TYPE_CAR,
  OPTIONS_TYPE_PLACE,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import {
  formatPeriod,
  formatPeriodTime,
  mapDebtStatus,
} from "../../debt/component/VIDebtTable";
import { options_debt } from "../../balance/styles/Card";
import { CardDebt } from "../../debt/styles/Card";

function PdfPageDebtOneSum({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);

  const debtShortItems = useMemo(() => {
    const newDebtShortItems = [];

    if (balanceData?.debt_short_list?.length > 0) {
      for (const debt_short_item of balanceData?.debt_short_list) {
        const keys = Object.keys(debt_short_item);
        for (const key of keys) {
          if (_.isArray(debt_short_item[key])) {
            for (const item of debt_short_item[key]) {
              let createItem = {
                title: "",
                unpaid_amount: item?.unpaid_amount,
                pay_permonth: item?.pay_permonth,
                interest: item?.interest,
                period: item?.period,
                period_time: "",
                type_payment: "",
                type_payment_ori: item?.type_payment,
                label: item?.creditor_name,
                status: options_debt.find(
                  (option) => option?.value === item?.status
                ),
                unit_interest: "ต่อปี",
                sort: 0,
              };

              // เครดิตการ์ด
              if (debt_short_item?.options_debt_short === "debt_creditcard") {
                const creditor = calDebtCreditCard(item);
                const period = creditor.creditor_items.length;
                const typePayment = !item?.type_payment
                  ? ""
                  : item?.type_payment === "pay_minimum"
                  ? "จ่ายขั้นต่ำ(รายเดือน)"
                  : item?.type_payment === "pay_full"
                  ? "จ่ายเต็มจำนวน"
                  : "จ่ายแบบระบุจำนวน(รายเดือน)";
                createItem.period = !item?.type_payment ? "" : period;
                createItem.period_time = !item?.type_payment
                  ? ""
                  : formatPeriodTime(period);
                createItem.type_payment = typePayment;
                createItem.title = "บัตรเครดิต";
                createItem.sort = 1;
              }

              if (debt_short_item?.options_debt_short === "debt_shortterm") {
                createItem.sort = 2;
                createItem.type_payment = "รายเดือน";
              } else if (
                debt_short_item?.options_debt_short ===
                "debt_informal_shortterm"
              ) {
                createItem.sort = 3;
                createItem.type_payment = "รายวัน";
                createItem.period = `${item?.number_of_days} วัน`;
              } else if (
                debt_short_item?.options_debt_short === "debt_shortterm_other"
              ) {
                createItem.sort = 4;
                createItem.type_payment = "รายเดือน";
                createItem.unit_interest = "ต่อเดือน";
              }

              // เงินกู้ระยะสั้น, หนี้นอกระบบ, เงินกู้ระยะสั้นอื่นๆ
              if (
                [
                  "debt_shortterm",
                  "debt_informal_shortterm",
                  "debt_shortterm_other",
                ].includes(debt_short_item?.options_debt_short)
              ) {
                createItem.period_time = formatPeriodTime(item?.period);
              }

              if (
                debt_short_item?.options_debt_short ===
                "debt_informal_shortterm"
              ) {
                createItem.unpaid_amount = item?.start_amount;
              }
              newDebtShortItems.push(createItem);
            }
          }
        }
      }
    }

    return newDebtShortItems.sort((a, b) => a.sort - b.sort);
  }, [balanceData]);

  const debtLongItems = useMemo(() => {
    const newDebtLongItems = [];

    if (balanceData?.debt_long_list?.length > 0) {
      for (const debt_long_item of balanceData.debt_long_list) {
        const keys = Object.keys(debt_long_item);
        for (const key of keys) {
          if (_.isArray(debt_long_item[key])) {
            for (const item of debt_long_item[key]) {
              let createItem = {
                unpaid_amount: item?.unpaid_amount,
                pay_permonth: item?.pay_permonth,
                interest: item?.interest,
                period: item?.period,
                period_time: formatPeriodTime(item?.period),
                type_payment: "",
                label: item.title,
              };

              // รถยนต์
              if (debt_long_item?.options_debt_long === "debt_car") {
                const findTypeCar = OPTIONS_TYPE_CAR?.filter((thisType) => {
                  if (thisType.value === item?.type_car) return thisType;
                });
                if (findTypeCar) {
                  createItem.label = `${findTypeCar[0]?.label} ${item?.title_car}`;
                }
              }

              // สินเชื่อที่อยู่อาศัย
              if (debt_long_item?.options_debt_long === "debt_place") {
                const findTypePlace = OPTIONS_TYPE_PLACE?.filter((thisType) => {
                  if (thisType.value === item?.type_place) return thisType;
                });
                const typePlace = item?.type_place_other
                  ? item?.type_place_other
                  : findTypePlace?.[0]?.label;
                if (typePlace) {
                  createItem.label = `${typePlace} (${item?.address_place})`;
                }
              }

              newDebtLongItems.push(createItem);
            }
          }
        }
      }
    }
    return newDebtLongItems;
  }, [balanceData]);

  const mergeDebtItems = useMemo(() => {
    const newMergeDebtItems = [
      {
        label: "หนี้ระยะสั้น",
        items: debtShortItems,
      },
      // {
      //   label: "หนี้ระยะยาว",
      //   items: debtLongItems,
      // },
    ];
    return newMergeDebtItems;
  }, [balanceData]);

  const totalItem = useMemo(() => {
    let total = 0;
    for (const item of [...debtShortItems, ...debtLongItems]) {
      total += item.unpaid_amount;
    }
    return total;
  }, [balanceData]);

  const totalShortItem = useMemo(() => {
    let total = 0;
    for (const item of debtShortItems) {
      total += item.unpaid_amount;
    }
    return total;
  }, [balanceData]);

  const chunkDebtShortItems = useMemo(() => {
    const creditShortItems =
      balanceData?.debt_short_list?.filter(
        (item) => item?.options_debt_short === "debt_creditcard"
      ) || [];
    const splitCreditShortItems = creditShortItems?.flatMap((item) => {
      const chunkCreditItems = _.chunk(item?.credit_cards, 1);
      const newShortItems = chunkCreditItems.map((creditItem) => {
        return {
          ...item,
          options_debt_short: "debt_creditcard",
          credit_cards: creditItem,
        };
      });
      return newShortItems;
    });
    const shortTermShortItems =
      balanceData?.debt_short_list?.filter(
        (item) => item?.options_debt_short === "debt_shortterm"
      ) || [];
    const splitShortTermShortItems = shortTermShortItems?.flatMap((item) => {
      const chunkShortTermItems = _.chunk(item?.short_terms, 1);
      const newShortItems = chunkShortTermItems.map((shortItem) => {
        return {
          ...item,
          options_debt_short: "debt_shortterm",
          short_terms: shortItem,
        };
      });
      return newShortItems;
    });
    const informalShortItems =
      balanceData?.debt_short_list?.filter(
        (item) => item?.options_debt_short === "debt_informal_shortterm"
      ) || [];
    const splitInformalShortItems = informalShortItems?.flatMap((item) => {
      const chunkInformalShortItems = _.chunk(item?.informal_short_terms, 1);
      const newShortItems = chunkInformalShortItems.map((informalItem) => {
        return {
          ...item,
          options_debt_short: "debt_informal_shortterm",
          informal_short_terms: informalItem,
        };
      });
      return newShortItems;
    });
    const otherShortItems =
      balanceData?.debt_short_list?.filter(
        (item) => item?.options_debt_short === "debt_shortterm_other"
      ) || [];
    const splitOtherShortItems = otherShortItems?.flatMap((item) => {
      const chunkOtherShortItems = _.chunk(item?.short_term_others, 1);
      const newShortItems = chunkOtherShortItems.map((otherItem) => {
        return {
          ...item,
          options_debt_short: "debt_shortterm_other",
          short_term_others: otherItem,
        };
      });
      return newShortItems;
    });
    const carLongItems =
      balanceData?.debt_long_list?.filter(
        (item) => item?.options_debt_long === "debt_car"
      ) || [];
    const splitCarLongItems = carLongItems?.flatMap((item) => {
      const chunkCarLongItems = _.chunk(item?.debt_long_cars, 1);
      const newLongItems = chunkCarLongItems.map((carItem) => {
        return {
          ...item,
          options_debt_long: "debt_car",
          debt_long_cars: carItem,
        };
      });
      return newLongItems;
    });
    const placeLongItems =
      balanceData?.debt_long_list?.filter(
        (item) => item?.options_debt_long === "debt_place"
      ) || [];
    const splitPlaceLongItems = placeLongItems?.flatMap((item) => {
      const chunkPlaceLongItems = _.chunk(item?.debt_long_places, 1);
      const newLongItems = chunkPlaceLongItems.map((placeItem) => {
        return {
          ...item,
          options_debt_long: "debt_place",
          debt_long_places: placeItem,
        };
      });
      return newLongItems;
    });
    const otherLongItems =
      balanceData?.debt_long_list?.filter(
        (item) => item?.options_debt_long === "debt_long_other"
      ) || [];
    const splitOtherLongItems = otherLongItems?.flatMap((item) => {
      const chunkOtherLongItems = _.chunk(item?.debt_long_others, 1);
      const newLongItems = chunkOtherLongItems.map((otherItem) => {
        return {
          ...item,
          options_debt_long: "debt_long_other",
          debt_long_others: otherItem,
        };
      });
      return newLongItems;
    });
    const newChunkDebtItems = _.chunk(
      [
        ...splitCreditShortItems,
        ...splitShortTermShortItems,
        ...splitInformalShortItems,
        ...splitOtherShortItems,
        ...splitCarLongItems,
        ...splitPlaceLongItems,
        ...splitOtherLongItems,
      ],
      2
    );
    return newChunkDebtItems;
  }, [balanceData]);

  return (
    <div className="flex flex-col gap-1">
      {chunkDebtShortItems.map((chunk, index) => {
        return (
          <div key={index} className={cn(STYLE_PAGE)}>
            <PdfHeader />
            <PdfLine />
            <PdfHeaderText
              title="แผนจัดการหนี้สิน"
              icon="/images/wealth_1.svg"
            />

            <div className="mt-2 !text-xs">
              {/*---- บัตรเครดิต ----*/}
              {chunk?.map((item, index) => {
                if (item?.options_debt_short === "debt_creditcard")
                  return (
                    <div key={index} className="">
                      {item?.credit_cards?.map((item2, index) => {
                        const creditor = calDebtCreditCard(item2);

                        return (
                          <div key={index} className="mb-4 !text-xs">
                            <CardDebt
                              title={`บัตรเครดิต`}
                              classNameTitle="!text-[0.6rem]"
                              stylehead={cn("!text-sm font-semibold")}
                              classNamePanel={cn("space-y-[0.1rem]")}
                              name={item2?.creditor_name}
                              total_start_overdue={item2?.unpaid_amount}
                              total_overdue={item2?.unpaid_amount}
                              options_debt_short="creditcard"
                              isCreditCard
                              interest_loan_peryear={item2?.interest}
                              total_payment={item2?.pay_permonth}
                              period={Math.ceil(
                                item2?.unpaid_amount / item2?.pay_permonth
                              )}
                              periodCustom={creditor?.creditor_items?.length}
                              total_creditor={creditor.total_creditor}
                              total_interest_loan={
                                creditor.total_creditor_interest
                              }
                              type_payment_ori={item2?.type_payment}
                              total_loan={item2?.unpaid_amount}
                              type_payment={
                                item2?.type_payment === "pay_minimum"
                                  ? "จ่ายขั้นต่ำ"
                                  : item2?.type_payment === "pay_full"
                                  ? "จ่ายเต็มจำนวน"
                                  : "จ่ายแบบระบุจำนวน"
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                if (item?.options_debt_short === "debt_shortterm")
                  return (
                    <div key={index} className="">
                      {item?.short_terms?.map((item2, index) => {
                        const loanDetails = generateAmortizationSchedule(
                          item2.unpaid_amount,
                          item2.interest,
                          item2.pay_permonth
                        );

                        const totalInterest = loanDetails.reduce(
                          (acc, item) => {
                            return acc + item?.interestPayment;
                          },
                          0
                        );
                        const totalCreditor = round(
                          item2?.unpaid_amount + totalInterest,
                          0
                        );

                        return (
                          <div key={index} className="mb-4">
                            <CardDebt
                              title={`หนี้กู้ระยะสั้น`}
                              classNameTitle="!text-[0.6rem]"
                              stylehead={cn("!text-sm font-semibold")}
                              classNamePanel={cn("space-y-[0.1rem]")}
                              name={item2?.creditor_name}
                              total_start_overdue={item2?.unpaid_amount}
                              total_overdue={item2?.unpaid_amount}
                              interest_loan_peryear={item2?.interest}
                              total_payment={item2?.pay_permonth}
                              period={item2?.period || 0}
                              total_creditor={totalCreditor}
                              total_interest_loan={round(totalInterest,0)}
                              total_loan={item2?.unpaid_amount}
                              type_payment={
                                item2?.type_payment === "pay_minimum"
                                  ? "จ่ายขั้นต่ำ"
                                  : item2?.type_payment === "pay_full"
                                  ? "จ่ายเต็มจำนวน"
                                  : "จ่ายแบบระบุจำนวน"
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                if (item?.options_debt_short === "debt_informal_shortterm")
                  return (
                    <div key={index} className="">
                      {item?.informal_short_terms?.map((item2, index) => {
                        // เป็นดอกเบี้ยเงินกู้
                        const totalInterestLoan = () => {
                          // let result = 0;
                          // result += totalCreditor() - item2?.unpaid_amount;
                          // return result;
                          const interest_period =
                            item2?.unpaid_amount * (item2.interest / 100);
                          let interest = 0;
                          let period = 1;
                          if (item2.compounding_type === "daily") {
                            period = 365;
                          } else if (item2.compounding_type === "weekly") {
                            period = 52;
                          } else if (item2.compounding_type === "monthly") {
                            period = 12;
                          }

                          interest = interest_period * period;
                          return interest;
                        };

                        const getCompoundingType = () => {
                          if (item2.compounding_type === "daily") {
                            return "รายวัน";
                          } else if (item2.compounding_type === "weekly") {
                            return "รายสัปดาห์";
                          } else if (item2.compounding_type === "monthly") {
                            return "รายเดือน";
                          }
                          return "รายปี";
                        };

                        return (
                          <div key={index} className="mb-4">
                            <CardDebt
                              title={`หนี้นอกระบบ (รายวัน)`}
                              classNameTitle="!text-[0.6rem]"
                              stylehead={cn("!text-sm font-semibold")}
                              classNamePanel={cn("space-y-[0.1rem]")}
                              name={item2?.creditor_name}
                              total_start_overdue={item2?.unpaid_amount}
                              total_overdue={item2?.start_amount}
                              number_of_days={item2?.number_of_days}
                              compounding_type={getCompoundingType()}
                              interest_loan_peryear={item2?.interest}
                              total_payment={item2?.pay_permonth}
                              period={item2?.period || 0}
                              total_creditor={
                                item2.pay_permonth * item2?.number_of_days
                              }
                              total_interest_loan={
                                item2.pay_permonth * item2?.number_of_days -
                                item2?.start_amount
                              }
                              total_loan={item2?.start_amount}
                              type_payment={""}
                              pay_permonth={item2?.pay_permonth}
                              options_debt_short={item?.options_debt_short}
                              year={item2?.year}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                if (item?.options_debt_short === "debt_shortterm_other")
                  return (
                    <div key={index} className="">
                      {item?.short_term_others?.map((item2, index) => {
                        const creditor = calTotalCreditorFloatInterest(item2);

                        const totalInterest = creditor.reduce((acc, item) => {
                          return acc + item.interest;
                        }, 0);

                        const totalCreditorInterest = creditor.reduce(
                          (acc, item, index) => {
                            if (index > 11) {
                              return acc;
                            }
                            return acc + item.interest;
                          },
                          0
                        );

                        return (
                          <div key={index} className="mb-4">
                            <CardDebt
                              title={`หนี้กู้ระยะสั้น (ดอกลอย)`}
                              classNameTitle="!text-[0.6rem]"
                              stylehead={cn("!text-sm font-semibold")}
                              classNamePanel={cn("space-y-[0.1rem]")}
                              name={item2?.creditor_name}
                              options_debt_short="debt_shortterm_other"
                              total_start_overdue={item2?.unpaid_amount}
                              total_overdue={item2?.unpaid_amount}
                              pay_permonth={item2?.pay_permonth}
                              interest_loan_peryear={item2?.interest}
                              total_payment={item2?.pay_permonth}
                              period={item2?.period || 0}
                              total_creditor={
                                item2?.unpaid_amount + totalCreditorInterest
                              }
                              total_interest_loan={totalCreditorInterest}
                              total_loan={item2?.unpaid_amount}
                              total_creditor2={
                                item2?.unpaid_amount + totalInterest
                              }
                              total_interest_loan2={totalInterest}
                              total_loan2={item2?.unpaid_amount}
                              type_payment={""}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                if (item?.options_debt_long === "debt_car")
                  return (
                    <div key={index} className="">
                      {item?.debt_long_cars?.map((item2, index) => {
                        const findTypeCar = OPTIONS_TYPE_CAR?.filter(
                          (thisType) => {
                            if (thisType.value === item2?.type_car)
                              return thisType;
                          }
                        );
                        const typeCar = findTypeCar?.[0]?.label;
                        // เจ้าหนี้ได้เงินทั้งสิน
                        const totalCreditor = () => {
                          let result = 0;
                          result +=
                            item2?.unpaid_amount +
                            item2?.unpaid_amount * (item2?.interest / 100);
                          return result;
                        };
                        // เป็นดอกเบี้ยเงินกู้
                        const totalInterestLoan = () => {
                          let result = 0;
                          result += totalCreditor() - item2?.unpaid_amount;
                          return result;
                        };

                        const getTypePayment = () => {
                          const findOptionPayment = options_debt.find(
                            (debtItem) => {
                              return debtItem.value === item2?.status;
                            }
                          );
                          return findOptionPayment?.label;
                        };

                        let total_creditor =
                          item2?.pay_permonth * item2?.period;
                        let total_interest_loan =
                          total_creditor - item2?.unpaid_amount;

                        return (
                          <div key={index} className="mb-4">
                            <CardDebt
                              title={
                                typeCar
                                  ? `${typeCar} ${item2?.title_car}`
                                  : item2?.title_car
                              }
                              total_start_overdue={item2?.unpaid_amount}
                              total_overdue={item2?.unpaid_amount}
                              // periodCustom={`${item2?.period} ปี`}
                              interest_loan_peryear={item2?.interest}
                              total_payment={item2?.pay_permonth}
                              period={item2?.period || 0}
                              total_creditor={total_creditor}
                              total_interest_loan={total_interest_loan}
                              total_loan={item2?.unpaid_amount}
                              type_payment={getTypePayment()}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                if (item?.options_debt_long === "debt_place")
                  return (
                    <div key={index} className="">
                      {item?.debt_long_places?.map((item2, index) => {
                        const findTypePlace = OPTIONS_TYPE_PLACE?.filter(
                          (thisType) => {
                            if (thisType.value === item2?.type_place)
                              return thisType;
                          }
                        );
                        const typePlace = item2?.type_place_other
                          ? item2?.type_place_other
                          : findTypePlace?.[0]?.label;
                        // เจ้าหนี้ได้เงินทั้งสิน
                        const totalCreditor = () => {
                          let result = 0;
                          result +=
                            item2?.start_amount +
                            item2?.start_amount * (item2?.interest / 100);
                          return result;
                        };
                        // เป็นดอกเบี้ยเงินกู้
                        const totalInterestLoan = () => {
                          let result = 0;
                          result += totalCreditor() - item2?.start_amount;
                          return result;
                        };

                        const getTypePayment = () => {
                          const findOptionPayment = options_debt.find(
                            (debtItem) => {
                              return debtItem.value === item2?.status;
                            }
                          );
                          return findOptionPayment?.label;
                        };

                        let total_creditor =
                          item2?.pay_permonth * item2?.period * 12;
                        let total_interest_loan =
                          total_creditor - item2?.unpaid_amount;

                        return (
                          <div key={index} className="mb-4">
                            <CardDebt
                              title={
                                typePlace
                                  ? `${typePlace} ${item2?.address_place}`
                                  : item2?.address_place
                              }
                              total_start_overdue={item2?.unpaid_amount}
                              total_overdue={item2?.unpaid_amount}
                              typeDebt="place"
                              interest_loan_peryear={item2?.interest}
                              total_payment={item2?.pay_permonth}
                              period={item2?.period || 0}
                              total_creditor={total_creditor}
                              total_interest_loan={total_interest_loan}
                              total_loan={item2?.unpaid_amount}
                              type_payment={getTypePayment()}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                if (item?.options_debt_long === "debt_long_other")
                  return (
                    <div key={index} className="">
                      {item?.debt_long_others?.map((item2, index) => {
                        // เจ้าหนี้ได้เงินทั้งสิน
                        const totalCreditor = () => {
                          let result = 0;
                          result +=
                            item2?.start_amount +
                            item2?.start_amount * (item2?.interest / 100);
                          return result;
                        };
                        // เป็นดอกเบี้ยเงินกู้
                        const totalInterestLoan = () => {
                          let result = 0;
                          result += totalCreditor() - item2?.start_amount;
                          return result;
                        };

                        let total_creditor =
                          item2?.pay_permonth * item2?.period;
                        let total_interest_loan =
                          total_creditor - item2?.unpaid_amount;

                        return (
                          <div key={index} className="mb-4">
                            <CardDebt
                              title={item2?.title}
                              total_start_overdue={item2?.unpaid_amount}
                              total_overdue={item2?.unpaid_amount}
                              typeDebt="long_other"
                              interest_loan_peryear={item2?.interest}
                              total_payment={item2?.pay_permonth}
                              period={item2?.period || 0}
                              total_creditor={total_creditor}
                              total_interest_loan={total_interest_loan}
                              total_loan={item2?.start_amount}
                              type_payment={
                                item2?.status === "normal"
                                  ? "ปกติ"
                                  : item2?.status
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
              })}
            </div>
            <PdfFooter page={page + index} />
          </div>
        );
      })}
    </div>
  );
}

export default PdfPageDebtOneSum;
